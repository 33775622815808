import { useEffect } from 'react';
import { Responders } from 'react-beautiful-dnd';
import eventEmitter from 'shared/foreground/eventEmitter';
import { ForegroundEventName } from 'shared/foreground/models';

export const useDragAndDropResponders = ({
  onDragStart,
  onDragEnd,
}: { onDragStart?: Responders['onDragStart']; onDragEnd: Responders['onDragEnd'] }) => {
  useEffect(() => {
    if (onDragEnd) {
      eventEmitter.on(ForegroundEventName.OnDragEnd, onDragEnd);
    }

    if (onDragStart) {
      eventEmitter.on(ForegroundEventName.OnDragStart, onDragStart);
    }

    return () => {
      eventEmitter.off(ForegroundEventName.OnDragEnd, onDragEnd);
      if (onDragStart) {
        eventEmitter.off(ForegroundEventName.OnDragStart, onDragStart);
      }
    };
  }, [onDragEnd, onDragStart]);
};
